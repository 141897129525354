<template>
    <div class="viewer-container" :class="selectedMenu">
      <BackButton v-if="selectedMenu === ''" :go-back="back" />
      <ConfirmButton v-if="isDetail && selectedMenu === ''" :go-back="handleExportAvatar" />
      <AssetList
          v-if="isDetail && (selectedMenu === '' || selectedMenu === 'asset')"
          :panel-visible="hairPanelVisible"
          :hair-clicked="assetSelected"
          :hair-color-changed="colorSelected"
          :toggle="assetOpen"
          :list="assetList">
      </AssetList>
      <BodyList
          v-if="isDetail && (selectedMenu === '' || selectedMenu === 'body')"
          :class="selectedMenu === 'body' ? 'active' : ''"
          :panel-visible="bodyPanelVisible"
          :body-clicked="bodySelected"
          :toggle="bodyOpen"
          :list="bodyList">

      </BodyList>

      <div ref="scene" class="iframe-container"></div>
      <div v-if="isLoading" class="spinner-wrapper2">
        <div class="spinner2"></div>
        <MatchyLogo3 />

      </div>
    </div>
</template>
<script>
import MatchyLogo3 from "@/components/MatchyLogo3.vue";
import { AvaturnSDK } from '@avaturn/sdk';
import { markRaw } from 'vue';
import BackButton from "@/components/BackButton.vue";
import ConfirmButton from "@/components/ConfirmButton.vue";
import AssetList from "@/components/AssetList.vue";
import BodyList from "@/components/BodyList.vue";
export default {
  components: {
    BodyList,
    AssetList,
    BackButton,
    ConfirmButton,
    MatchyLogo3
  },
  props: {
    url: {
      type: String,
      default: null
    },
    goBack: Function,
    avatarLoaded: Function,
    reset: Function
  },
  data() {
    return {
      sdk: null,
      assetOpen: false,
      bodyOpen: false,
      bodyList: [],
      assetList: [],
      isLoading: false,
      isDetail: false,
      gender: null,
      selectedMenu: '',
      token: 'PsP6tfiW-Au4JbD8QE9RVb8I6UDSWPpgP2JQ0xx07xs-mzzK2XDbv5EltHJyalPp1qNM_5vXNJgfcLqJdBMDUg'
    };
  },
  async mounted() {
    await this.initSdk();
    //this.injectIframe();

  },
  beforeUnmount() {
    // Cleanup to prevent memory leaks
  },
  methods: {
    bodyPanelVisible (value) {
      if (value) {
        this.selectedMenu = 'body';
      } else {
        this.selectedMenu = '';
      }
      this.bodyOpen = value
      if (value && this.assetOpen) {
        this.assetOpen = false;
      }
    },
    colorSelected (color) {
      console.log('sdk', this.sdk, color);
      this.sdk.setParam("hair_color", color);
      //this.hairPanelVisible(false);
    },
    hairPanelVisible(value) {
      if (value) {
        this.selectedMenu = 'asset';
      } else {
        this.selectedMenu = '';
      }
      this.assetOpen = value
      console.log('hair is open', value, this.bodyOpen);
      if (value && this.bodyOpen) {
        this.bodyOpen = false;
      }
    },
    bodySelected (body) {
      this.sdk.setActiveBody(body.id).then(() => {
        console.log(`Body changed: ${body.id}`);

        //this.bodyPanelVisible(false);
      });
    },
    back () {
      console.log('vars', this.bodyOpen, this.assetOpen, this.isDetail)
      if (this.bodyOpen) {
        this.bodyOpen = false
      } else if (this.assetOpen) {
        this.assetOpen = false
      } else {
        this.goBack('scene');
      }
    },
    injectIframe() {
      setTimeout(() => {
        const iframe = document.getElementById('avaturn-sdk-iframe');
        if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
          const style = document.createElement('style');
          style.textContent = `
          .hub, .insertable-hub {
              background: #fff;
          }
        `;
          iframe.contentWindow.document.head.appendChild(style);
        }
      }, 1000);
    },
    handleExportAvatar () {
      this.isLoading = true;
      console.log(`Calling sdk.exportAvatar()`);
      this.sdk.exportAvatar().then((res) => {
        console.log(res);
        console.log("[then] Avatar exported: ", res);
        this.isLoading = false;
        this.avatarLoaded(res);
      }).catch(() => {
        this.isLoading = false;
      });
    },
    assetSelected (asset) {
      this.sdk.setActiveAsset(asset.id).then(() => {
        console.log(`Asset changed: ${asset.id}`);
        //this.hairPanelVisible(false);
      });
    },
    async initSdk () {
      this.isLoading = false;
      this.sdk = markRaw(new AvaturnSDK());
      this.sdk.init(this.$refs.scene, {
        url: this.url,
        iframeClassName: 'iframe-wrapper',
        token: this.token,
        disableUi: true
      }).then((result) => {
        console.log('loaded', result);
        this.isLoading = false;
        this.sdk.on("load", (data) => {
          console.log("[callback] load: ", data);
          this.sdk.getBodyList().then((list) => {
            console.log('body', list)
            this.bodyList = list.filter(item => item.id !== 'body_2');
            this.gender = list[0].gender;
            this.isDetail = true;
            this.sdk.getAssetList().then((list) => {
              this.assetList = list;
              console.log('assets', list);
            });
          });

        });
      }).catch((reason) => {
          console.log(`[Avaturn SDK Error]: ${reason}`);
          this.isLoading = false;
        });
    },
  }
};
</script>
